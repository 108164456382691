/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { TechSharedModule } from '@alfa-client/tech-shared';
import { UiModule } from '@alfa-client/ui';
import { UserProfileSharedModule } from '@alfa-client/user-profile-shared';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonWithSpinnerComponent } from '@ods/component';
import {
  AccountCircleIconComponent,
  DropdownMenuButtonItemComponent,
  DropdownMenuComponent,
  DropdownMenuItemComponent,
  ErrorIconComponent,
  LogoutIconComponent,
  UserIconComponent as OdsUserIconComponent,
  TooltipDirective,
} from '@ods/system';
import { AssignUserProfileButtonContainerComponent } from './assign-user-profile-button-container/assign-user-profile-button-container.component';
import { LinkWithUserNameTooltipContainerComponent } from './link-with-user-name-tooltip-container/link-with-user-name-tooltip-container.component';
import { LinkWithUserNameTooltipComponent } from './link-with-user-name-tooltip-container/link-with-user-name-tooltip/link-with-user-name-tooltip.component';
import { TextWithUserNameTooltipContainerComponent } from './text-with-user-name-tooltip-container/text-with-user-name-tooltip-container.component';
import { TextWithUserNameTooltipComponent } from './text-with-user-name-tooltip-container/text-with-user-name-tooltip/text-with-user-name-tooltip.component';
import { UserIconComponent } from './user-icon/user-icon.component';
import { UserProfileInHeaderContainerComponent } from './user-profile-in-header-container/user-profile-in-header-container.component';
import { UserProfileInHeaderComponent } from './user-profile-in-header-container/user-profile-in-header/user-profile-in-header.component';
import { UserProfileInHistorieContainerComponent } from './user-profile-in-historie-container/user-profile-in-historie-container.component';
import { UserProfileInKommentarContainerComponent } from './user-profile-in-kommentar-container/user-profile-in-kommentar-container.component';
import { UserProfileInPostfachMailContainerComponent } from './user-profile-in-postfach-mail-container/user-profile-in-postfach-mail-container.component';
import { UserProfileInVorgangContainerComponent } from './user-profile-in-vorgang-container/user-profile-in-vorgang-container.component';
import { UserProfileButtonContainerComponent } from './user-profile-in-vorgang-container/user-profile-in-vorgang/user-profile-button-container/user-profile-button-container.component';
import { UserProfileInVorgangComponent } from './user-profile-in-vorgang-container/user-profile-in-vorgang/user-profile-in-vorgang.component';
import { UserProfileInVorgangListItemContainerComponent } from './user-profile-in-vorgang-list-item-container/user-profile-in-vorgang-list-item-container.component';
import { UserProfileNameComponent } from './user-profile-name/user-profile-name.component';
import { UserProfileSearchContainerComponent } from './user-profile-search-container/user-profile-search-container.component';
import { UserProfileSearchComponent } from './user-profile-search-container/user-profile-search/user-profile-search.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  imports: [
    CommonModule,
    TechSharedModule,
    UiModule,
    UserProfileSharedModule,
    RouterModule,
    OdsUserIconComponent,
    LogoutIconComponent,
    ErrorIconComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    DropdownMenuButtonItemComponent,
    ButtonWithSpinnerComponent,
    AccountCircleIconComponent,
    TooltipDirective,
  ],
  declarations: [
    UserIconComponent,
    UserProfileInVorgangContainerComponent,
    UserProfileSearchComponent,
    UserProfileSearchContainerComponent,
    AssignUserProfileButtonContainerComponent,
    UserProfileInKommentarContainerComponent,
    UserProfileInVorgangListItemContainerComponent,
    UserProfileButtonContainerComponent,
    UserProfileInPostfachMailContainerComponent,
    UserProfileComponent,
    UserProfileInVorgangComponent,
    LinkWithUserNameTooltipContainerComponent,
    LinkWithUserNameTooltipComponent,
    TextWithUserNameTooltipContainerComponent,
    TextWithUserNameTooltipComponent,
    UserProfileInHistorieContainerComponent,
    UserProfileNameComponent,
    UserProfileInHeaderContainerComponent,
    UserProfileInHeaderComponent,
  ],
  exports: [
    UserProfileInVorgangContainerComponent,
    UserIconComponent,
    AssignUserProfileButtonContainerComponent,
    UserProfileInKommentarContainerComponent,
    UserProfileInVorgangListItemContainerComponent,
    UserProfileInPostfachMailContainerComponent,
    LinkWithUserNameTooltipContainerComponent,
    TextWithUserNameTooltipContainerComponent,
    UserProfileInHistorieContainerComponent,
    UserProfileInHeaderContainerComponent,
  ],
})
export class UserProfileModule {}
