/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TextareaComponent } from '@ods/system';
import { FormControlEditorAbstractComponent } from '../formcontrol-editor.abstract.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'ods-textarea-editor',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TechSharedModule,
    TextareaComponent,
    ValidationErrorComponent,
  ],
  templateUrl: './textarea-editor.component.html',
})
export class TextareaEditorComponent extends FormControlEditorAbstractComponent {
  @Input({ required: true }) label: string;
  @Input() placeholder: string = '';
  @Input() rows: number = 10;
  @Input() isRequired: boolean = false;
  @Input() focus: boolean = false;
  @Input() isResizable: boolean = true;
  @Input() showLabel: boolean = true;

  get variant(): string {
    return this.invalidParams.length > 0 ? 'error' : 'default';
  }
}
