import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'ods-routing-button',
  standalone: true,
  imports: [CommonModule, RouterLink],
  host: { class: 'block' },
  template: `<a
    [routerLink]="'/' + linkPath"
    [attr.data-test-id]="dataTestId"
    class="block min-h-9 w-fit min-w-32 rounded bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm outline-focus hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
  >
    {{ text }}
  </a>`,
})
export class RoutingButtonComponent {
  @Input() linkPath: string;
  @Input() text: string;
  @Input() dataTestId: string;
}
