/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { StateResource, TechSharedModule } from '@alfa-client/tech-shared';
import { NgForOf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
} from '@angular/forms';
import { Resource } from '@ngxp/rest';
import {
  AttachmentIconComponent,
  FileUploadButtonComponent,
  SpinnerIconComponent,
} from '@ods/system';
import { uniqueId } from 'lodash-es';
import { FormControlEditorAbstractComponent } from '../formcontrol-editor.abstract.component';

@Component({
  selector: 'ods-file-upload-editor',
  templateUrl: './file-upload-editor.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  standalone: true,
  imports: [
    FileUploadButtonComponent,
    AttachmentIconComponent,
    SpinnerIconComponent,
    ReactiveFormsModule,
    NgForOf,
    TechSharedModule,
  ],
})
export class FileUploadEditorComponent
  extends FormControlEditorAbstractComponent
  implements OnInit
{
  @Input() label: string = '';
  @Input() parentFormArrayName: string;
  @Input() accept: string = '*/*';
  @Input() uploadInProgress: StateResource<Resource>;
  @Input() set fileLinkList(value: string[]) {
    this.buildFormArray(value);
  }

  @Output() public newFile: EventEmitter<File> = new EventEmitter<File>();

  fileLinkControls: UntypedFormArray = new UntypedFormArray([]);

  readonly uploadFileId: string = uniqueId();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList): void {
    const file = event && event.item(0);
    this.upload(file);
    this.setErrors();
  }

  constructor(public parentForm: FormGroupDirective) {
    super(null);
  }

  override ngOnInit(): void {
    this.fileLinkControls = this.parentForm.form.get(this.parentFormArrayName) as UntypedFormArray;
  }

  buildFormArray(fileLinkList: string[]): void {
    this.fileLinkControls.clear();
    fileLinkList.forEach((link: string) =>
      this.fileLinkControls.push(new UntypedFormControl(link)),
    );
  }

  upload(file: File): void {
    this.newFile.emit(file);
  }
}
