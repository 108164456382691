/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFnsAdapter, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import {
  ArrowBackIconComponent,
  ButtonComponent,
  FileUploadButtonComponent,
  SpinnerIconComponent,
  TooltipDirective,
} from '@ods/system';
import { de } from 'date-fns/locale';
import { AppIconComponent } from '../icon/app-icon/app-icon.component';
import { PostfachIconComponent } from '../icon/postfach-icon/postfach-icon.component';
import { HttpConnectionTimeoutInterceptor } from '../interceptor/http-connection-timeout.interceptor';
import { HttpErrorInterceptor } from '../interceptor/http-error.interceptor';
import { SnackbarCloseButtonComponent } from '../snackbar/snackbar-close-button/snackbar-close-button.component';
import { SnackbarErrorComponent } from '../snackbar/snackbar-error/snackbar-error.component';
import { SnackbarInfoComponent } from '../snackbar/snackbar-info/snackbar-info.component';
import { AccordionComponent } from './accordion/accordion.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { BasicDialogComponent } from './basic-dialog/basic-dialog.component';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { AutocompleteEditorComponent } from './editor/autocomplete-editor/autocomplete-editor.component';
import { CheckboxEnumEditorComponent } from './editor/checkbox-enum-editor/checkbox-enum-editor.component';
import { DateEditorComponent } from './editor/date-editor/date-editor.component';
import { FileUploadEditorComponent } from './editor/file-upload-editor/file-upload-editor.component';
import { TextEditorComponent } from './editor/text-editor/text-editor.component';
import { TextAreaEditorComponent } from './editor/textarea-editor/textarea-editor.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FixedDialogComponent } from './fixed-dialog/fixed-dialog.component';
import { ConnectionTimeoutRetryDialogComponent } from './http-error-dialog/connection-timeout-retry-dialog/connection-timeout-retry-dialog.component';
import { ConnectionTimeoutRetryFailDialogComponent } from './http-error-dialog/connection-timeout-retry-fail-dialog/connection-timeout-retry-fail-dialog.component';
import { IconButtonWithSpinnerComponent } from './icon-button-with-spinner/icon-button-with-spinner.component';
import { InternalServerErrorDialogComponent } from './notification/internal-server-error-dialog/internal-server-error-dialog.component';
import { OpenUrlButtonComponent } from './open-url-button/open-url-button.component';
import { OzgcloudButtonWithSpinnerComponent } from './ozgcloud-button/ozgcloud-button-with-spinner/ozgcloud-button-with-spinner.component';
import { OzgcloudIconButtonPrimaryComponent } from './ozgcloud-button/ozgcloud-icon-button-primary/ozgcloud-icon-button-primary.component';
import { OzgcloudStrokedButtonWithSpinnerComponent } from './ozgcloud-button/ozgcloud-stroked-button-with-spinner/ozgcloud-stroked-button-with-spinner.component';
import { OzgcloudButtonContentComponent } from './ozgcloud-button/shared/ozgcloud-button-content/ozgcloud-button-content.component';
import { OzgcloudIconComponent } from './ozgcloud-icon/ozgcloud-icon.component';
import { OzgcloudMenuComponent } from './ozgcloud-menu/ozgcloud-menu.component';
import { OzgcloudPasteTextButtonComponent } from './ozgcloud-paste-text-button/ozgcloud-paste-text-button.component';
import { OzgcloudRoutingButtonComponent } from './ozgcloud-routing-button/ozgcloud-routing-button.component';
import { OzgcloudSvgIconBigComponent } from './ozgcloud-svgicon-big/ozgcloud-svgicon-big.component';
import { OzgcloudSvgIconComponent } from './ozgcloud-svgicon/ozgcloud-svgicon.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { SpinnerTransparencyComponent } from './spinner-transparency/spinner-transparency.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SubnavigationComponent } from './subnavigation/subnavigation.component';
import { ValidationErrorComponent } from './validation-error/validation-error.component';

@NgModule({
  declarations: [
    SubnavigationComponent,
    SpinnerComponent,
    OzgcloudButtonWithSpinnerComponent,
    OzgcloudStrokedButtonWithSpinnerComponent,
    IconButtonWithSpinnerComponent,
    ProgressBarComponent,
    ExpansionPanelComponent,
    SnackbarInfoComponent,
    SnackbarErrorComponent,
    FileUploadComponent,
    DateEditorComponent,
    TextEditorComponent,
    TextAreaEditorComponent,
    ValidationErrorComponent,
    AutocompleteEditorComponent,
    FixedDialogComponent,
    InternalServerErrorDialogComponent,
    FileUploadEditorComponent,
    PostfachIconComponent,
    AccordionComponent,
    AppIconComponent,
    SlideToggleComponent,
    BackButtonComponent,
    OzgcloudIconButtonPrimaryComponent,
    OzgcloudIconComponent,
    OzgcloudRoutingButtonComponent,
    OzgcloudSvgIconComponent,
    OzgcloudSvgIconBigComponent,
    SpinnerTransparencyComponent,
    OpenUrlButtonComponent,
    DownloadButtonComponent,
    ConnectionTimeoutRetryDialogComponent,
    ConnectionTimeoutRetryFailDialogComponent,
    SnackbarCloseButtonComponent,
    BasicDialogComponent,
    OzgcloudButtonContentComponent,
    OzgcloudPasteTextButtonComponent,
    CheckboxEnumEditorComponent,
    OzgcloudMenuComponent,
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRippleModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatDateFnsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTabsModule,
    MatBadgeModule,
    CommonModule,
    TechSharedModule,
    RouterModule,
    MatButtonToggleModule,
    FileUploadButtonComponent,
    SpinnerIconComponent,
    TooltipDirective,
    ButtonComponent,
    ArrowBackIconComponent,
  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatRippleModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatDateFnsModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTabsModule,
    MatBadgeModule,
    CommonModule,
    TechSharedModule,
    RouterModule,
    MatButtonToggleModule,
    SubnavigationComponent,
    SpinnerComponent,
    OzgcloudIconButtonPrimaryComponent,
    OzgcloudButtonWithSpinnerComponent,
    OzgcloudStrokedButtonWithSpinnerComponent,
    IconButtonWithSpinnerComponent,
    ProgressBarComponent,
    ExpansionPanelComponent,
    SnackbarInfoComponent,
    SnackbarErrorComponent,
    FileUploadComponent,
    DateEditorComponent,
    TextEditorComponent,
    TextAreaEditorComponent,
    ValidationErrorComponent,
    AutocompleteEditorComponent,
    FixedDialogComponent,
    InternalServerErrorDialogComponent,
    FileUploadEditorComponent,
    PostfachIconComponent,
    AccordionComponent,
    AppIconComponent,
    SlideToggleComponent,
    BackButtonComponent,
    OzgcloudIconComponent,
    OzgcloudRoutingButtonComponent,
    OzgcloudSvgIconComponent,
    OzgcloudSvgIconBigComponent,
    SpinnerTransparencyComponent,
    OpenUrlButtonComponent,
    DownloadButtonComponent,
    ConnectionTimeoutRetryDialogComponent,
    ConnectionTimeoutRetryFailDialogComponent,
    SnackbarCloseButtonComponent,
    BasicDialogComponent,
    OzgcloudPasteTextButtonComponent,
    CheckboxEnumEditorComponent,
    FileUploadButtonComponent,
    OzgcloudButtonContentComponent,
    OzgcloudMenuComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConnectionTimeoutInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: de,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class UiModule {}
